/* You can add global styles to this file, and also import other style files */
@charset "utf-8"
@import url('https://fonts.googleapis.com/css?family=Roboto:300,400,500,700')
@import "~@ng-select/ng-select/themes/default.theme.css"
// @import "~@ng-select/ng-select/themes/material.theme.css"
@import '~bootstrap/dist/css/bootstrap.min.css'

/* Top Level Definitions */
html, body
    height: 100%
    font-family: Roboto, sans-serif

body
    overflow-y: scroll
    -webkit-overflow-scrolling: touch
    font-family: Roboto, sans-serif
    margin: auto
    width: 1366px

body, html, h1, h2, h3, h4, h5, h6, p, a, input, header
    font-weight: 400
    color: #53565a
    padding: 0

h1
    font-size: 26px
    font-weight: 400
    color: #53565a

header
    color: #53565a
    font-weight: 400
    font-size: 18px

a
    cursor: pointer
    text-decoration: none
    font-size: 14px
    font-weight: 400
    color: #3f84c5
a:hover
    text-decoration: none !important
    color: #3f84c5

// filter and grid input
.form-control
    height: 36px !important
    border: 0.5px solid #939CA5 !important
    border-radius: 0 !important
    font-size: 14px !important
    padding-left: 3px !important
    padding-right: 3px !important

.form-control:focus
    border: 0.5px solid #939CA5 !important
    box-shadow: 0 0 0 0 !important

//buttons
.btn-custom-highlight-action
    border: 1px solid #3f84c5
    color: #3f84c5
    padding: 6px 20px 6px 20px
    border-radius: 3px
    cursor: pointer
    font-size: 14px
    display: inline-block
    text-align: center
    white-space: nowrap
    height: 36px
    width: fit-content
    background-color: #fff

.btn-custom-secondary
    border: 1px solid #3f84c5
    color: #3f84c5
    padding: 6px 20px 6px 20px
    border-radius: 3px
    cursor: pointer
    font-size: 14px
    display: inline-block
    text-align: center
    white-space: nowrap
    height: 36px
    min-width: 150px
    width: fit-content
    background-color: #fff

.btn-custom-primary
    border: 1px solid #3f84c5
    color: #fff
    padding: 6px 20px 6px 20px
    border-radius: 0.2rem
    cursor: pointer
    display: inline-block
    text-align: center
    white-space: nowrap
    background-color: #3f84c5
    font-size: 14px
    height: 36px
    min-width: 150px
    width: fit-content

.link-button
    color: #3f84c5
    font-size: 14px
    font-weight: 400
    cursor: pointer
    text-decoration: none
    border: none
    background-color: transparent
    height: 36px
    button: focus
        outline: none

.route-link
    color: #3f84c5
    font-size: 14px
    font-weight: 400
    cursor: pointer
    text-decoration: none
    border: none
    background-color: transparent

.cursor-pointer
    cursor: pointer !important

.custom-hr
    color: #979797
    height: 3px
    overflow: visible
    margin-top: 18px
    margin-bottom: 18px
    align-self: center
    width: 100%

.value-label
    font-size: 14px

.header-label
    font-weight: 700
    font-size: 14px

.disclaimer-text
    font-weight: 700
    font-size: 14px

.alert-icon
    height: 25px
    width: 25px
    margin-right: 15px

.font-red
    color: Red
    font-size: 14px

.details-page-main-outer
    padding: 20px 20px
    height: inherit
    margin-bottom: 20px
    background-color: white
    box-shadow: 0 0 3px 1px #dee0dd
    border: 1px solid #bac1c8
    border-radius: 5px

// Min Height for iPad Screens

@media only screen and (min-device-width: 768px) and (max-device-width: 1366px) and (orientation: landscape)
    body
        min-height: 1028px

@media only screen and (min-device-width: 768px) and (max-device-width: 1366px) and (orientation: potrait)
    body
        min-height: 1028px

@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: landscape) and (-webkit-min-device-pixel-ratio: 2)
    body
        min-height: 1028px

@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation:portrait)
    body
        min-height: 1028px

// // ng-select styling

// .ng-select
//     padding: 0
//     background-color: rgba(255, 255, 255 , 1)

// .ng-dropdown-panel .ng-dropdown-panel-items .ng-option.ng-option-marked
//     background-color: #007bff !important
//     color: #ffffff !important

// .ng-dropdown-panel.ng-select-bottom .ng-dropdown-panel-items .ng-option:last-child
//     border-bottom-right-radius: 0 !important
//     border-bottom-left-radius: 0 !important
// .ng-dropdown-panel.ng-select-top .ng-dropdown-panel-items .ng-option:first-child
//     border-radius: 0 0 0 0 !important
// .ng-dropdown-panel .ng-dropdown-panel-items .ng-option.ng-option-selected, .ng-dropdown-panel .ng-dropdown-panel-items .ng-option.ng-option-selected.ng-option-marked
//     background-color: #fff

.ng-dropdown-panel
    font-size: 14px
    z-index: 1070 !important
    width: fit-content !important
    white-space: nowrap
    border: 1px solid #939CA5

.ng-select.ng-select-disabled>.ng-select-container
    background-color: #e7e7e7
.ng-value
    font-size: 14px
    margin-right: 0.2rem

.ng-placeholder
    font-size: 14px !important
    color: #53565a !important

.ng-select .ng-select-container
    // min-height: 34px
    font-size: 13px
    color: #53565a !important
    border: .5px solid #939CA5!important
    border-radius: 0
    padding: 0px

.ng-dropdown-panel.ng-select-bottom
    border: 1px solid #939CA5
    border-bottom-right-radius: 0
    border-bottom-left-radius: 0
    margin-top: 5px
    box-shadow: 0px 0px 6px 2px rgb(200, 200, 200)

.ng-dropdown-panel.ng-select-top
    border: 1px solid #939CA5
    border-bottom-right-radius: 0
    border-bottom-left-radius: 0
    margin-bottom: 5px
    box-shadow: 0px 0px 6px 2px rgb(200, 200, 200)

.ng-dropdown-panel .ng-dropdown-panel-items .ng-option-marked
    background-color: #e7e7e7 !important
.ng-dropdown-panel .ng-dropdown-panel-items .ng-option-selected
    background-color: #cfe7ff !important
    .ng-option-label
            font-weight: 700
.ng-dropdown-panel .ng-dropdown-panel-items .ng-option-selected .ng-option-marked
    background-color: #cfe7ff !important
    .ng-option-label
            font-weight: 700
.ng-select.ng-select-multiple .ng-select-container .ng-value-container .ng-value
    font-weight: 700 !important
    border: 0.5px solid #939CA5
    background-color: #cfe7ff !important

.ng-select.ng-select-single .ng-select-container .ng-value-container .ng-input
    top: 0px !important

//this is for removing arrows from input type number
input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button
    -webkit-appearance: none
    -moz-appearance: none
    appearance: none
    margin: 0
input[type=text]
    // height: 36px
    box-sizing: border-box
input[type=password]
    // height: 36px
    box-sizing: border-box
input[type=number]
    // height: 36px
    box-sizing: border-box
input:focus
    outline: none
//Modal popup
.modal-holder-sm
    width: 75%
    left: auto

.modal-holder-sm .modal-sm .modal-content
    height: 300px

.modal-backdrop
    height: 100% !important
    width: 100% !important

.modal-content
    display: block !important
    border: 0 !important
    border-radius: 0 !important
    padding-right: 20px !important
    padding-left: 20px !important
    padding-top: 20px !important
    padding-bottom: 0 !important
    overflow-y: auto
    overflow-x: auto

.modal-header
    border-bottom: 0 !important
    padding: 0px !important

.modal-footer
    padding-left: 0 !important
    padding-right: 0 !important
    padding-top: 0 !important
    padding-bottom: 20px !important
    border: 0 !important

.modal-body
    padding-left: 0 !important
    padding-right: 0 !important
    padding-top: 0 !important
    padding-bottom: 20px !important

.modal-header
    img
        cursor: pointer
        height: 18px
        width: 18px
    .modal-title
        font-size: 26px !important

.modal-holder
    animation-name: example
    animation-duration: 0.5s

@keyframes example
    0%
        transform: scale(0.5)
    100%
        transform: scale(1)

.error-text
    color: red !important
    font-size: 14px
    align-content: center

.multi-select_display
    padding-top: 3px !important
    white-space: nowrap
    overflow-x: hidden
    text-overflow: ellipsis

// ng-select.ng-select-disabled>.ng-select-container
//     background-color: #e7e7e7 !important

.fit-content
    width: fit-content
    block-size: fit-content

button:disabled
    opacity: 0.5
    cursor: not-allowed
    pointer-events: unset

.table > :not(caption) > * > *
    border-bottom-width: 0 !important

.status-label
    font-weight: 700
    font-size: 14px
    min-width: min-content

.notes-input
    overflow: hidden
    resize: none

.router-disabled
    pointer-events: none
    cursor: default
    color: black

.warning-icon
    height: 20px
    width: 20px

.form-check-input
    appearance: auto